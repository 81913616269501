import React from "react"

import Typography from "../../components/shared/Typography"

import CTALink from "./CTALink"

const BottomCTA = () => (
  <section className="mx-auto flex max-w-splash-container py-10 pb-20 hplg:px-8 sm:p-0 sm:py-5 sm:pb-10">
    <div className="w-full bg-[url('~/public/images/homepage/bottom-cta.webp')] bg-cover bg-no-repeat bg-blend-multiply">
      <div className="h-full w-full bg-black bg-opacity-[0.64] p-24 sm:flex sm:h-[320px] sm:flex-col sm:justify-center sm:p-8">
        <Typography variant="hxl" className="leading-tight text-white sm:text-[24px]">
          Explore over 4,000+ holistic practitioners.
        </Typography>
        <Typography variant="h4" as="p" className="mb-6 mt-4 font-normal leading-normal text-white">
          The best practitioners are on Heal.me, book a session today.
        </Typography>
        <CTALink href="/practitioner-search">Find a practitioner</CTALink>
      </div>
    </div>
  </section>
)

export default BottomCTA
