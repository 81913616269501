import React from "react"

import BottomCTA from "./HomePage/BottomCTA"
import HeroSection from "./HomePage/HeroSection"
import PractitionerJoinCTA from "./HomePage/PractitionerJoinCTA"
import ReviewsSection from "./HomePage/ReviewsSection"
import TherapyCategoriesSection from "./HomePage/TherapyCategoriesSection"

const HomePage = ({ reviews, therapyCategories, isMobile }) => (
  <div>
    <HeroSection />
    <ReviewsSection reviews={reviews} isMobile={isMobile} />
    <TherapyCategoriesSection therapyCategories={therapyCategories} />
    <PractitionerJoinCTA />
    <BottomCTA />
  </div>
)

export default HomePage
