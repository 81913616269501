import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

import { practitionerWebsiteUrl } from "../../@core/practitioner/practitioner.utils"
import { Box, Divider } from "../../components/shared/Layout"
import ReviewStars from "../../components/shared/ReviewStars"
import Typography from "../../components/shared/Typography"

const ReviewCard = ({ review, className }) => {
  const isAnonymous = review.isAnonymous
  const reviewText = review.review.trim()
  const name = review.client?.firstName || review.user?.firstName
  const lastName = review.client?.lastName || review.user?.lastName
  const firstName = name && name.split(" ")[0]
  const firstInitial = firstName && `${firstName[0]}.`?.toUpperCase()
  const lastInitial = (lastName && `${lastName[0]}.`?.toUpperCase()) || (name && name.split(" ")[1]?.toUpperCase())

  return (
    <Box
      as="a"
      href={practitionerWebsiteUrl(review.practice.user, review.practice)}
      target="_blank"
      key={review.id}
      keepBorderInMobile={true}
      className={twMerge(
        "flex min-h-[328px] flex-col transition-opacity duration-700 ease-in-out focus:outline-none focus:ring-4 sm:min-h-[285px]",
        className
      )}>
      <div className="line-clamp-5 text-black" dangerouslySetInnerHTML={{ __html: reviewText }}></div>
      <div className="mt-auto">
        <div className="flex w-full flex-wrap items-center gap-2">
          <Typography variant="small" as="div" className="hidden text-gray-dark sm:block">
            {isAnonymous ? "Anonymous" : `${firstInitial} ${lastInitial}`} • {review.createdAtAgoInWords}
          </Typography>
          <Typography variant="small" as="div" className="text-gray-dark sm:hidden">
            {isAnonymous ? "Anonymous" : `${firstName}${lastInitial ? ` ${lastInitial}` : ""}`} •{" "}
            {review.createdAtAgoInWords}
          </Typography>
          <ReviewStars rating={review.rating} />
        </div>
        <Divider className="my-4" />
        <div className="flex w-full items-center gap-3 truncate">
          <img
            src={review.practice.user.smallPhotoUrl}
            alt="Practitioner photo"
            className="h-12 w-12 rounded-full object-cover object-center"
          />
          <div className="flex flex-col truncate">
            <div className="flex items-center gap-1">
              <span className="truncate font-bold">{review.practice.user.name}</span>
              <img className="h-5 w-5" src="/images/email/verified.png" alt="Verified" />
            </div>
            <Typography variant="smSubtitle" className="truncate">
              {review.practice.user.title}
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  )
}

const ReviewsSection = ({ reviews }) => {
  const defaultNumReviewsShown = 3
  const [reviewsState, setReviewsState] = useState({
    numReviewsShown: defaultNumReviewsShown,
    shownReviews: reviews.slice(0, defaultNumReviewsShown),
    nextReviewIndex: defaultNumReviewsShown,
    nextSlotIndex: 0
  })
  const [startAnimation, setStartAnimation] = useState(false)

  useEffect(() => {
    if (reviews.length === 0) return

    const interval = setInterval(() => {
      setStartAnimation(true)
      setTimeout(() => {
        setReviewsState((prevState) => {
          const nextReview = reviews[prevState.nextReviewIndex]
          const newShownReviews = [...prevState.shownReviews]
          newShownReviews[prevState.nextSlotIndex] = nextReview
          return {
            ...prevState,
            shownReviews: newShownReviews,
            nextReviewIndex: (prevState.nextReviewIndex + 1) % reviews.length
          }
        })
      }, 650)

      setTimeout(() => {
        setStartAnimation(false)
        setReviewsState((prevState) => ({
          ...prevState,
          nextSlotIndex: (prevState.nextSlotIndex + 1) % prevState.numReviewsShown
        }))
      }, 700)
    }, 3000)
    return () => clearTimeout(interval)
  }, [])

  return (
    <section className="mx-auto max-w-splash-container py-10 hplg:px-8 sm:px-6 sm:py-5">
      <Typography variant="h3" as="h2" className="mb-4">
        Recent client reviews
      </Typography>
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-1">
        {reviewsState.shownReviews.map((review, i) => (
          <ReviewCard
            key={review.id}
            review={review}
            className={
              startAnimation && i === reviewsState.nextSlotIndex % reviewsState.numReviewsShown
                ? "opacity-0"
                : "opacity-100"
            }
          />
        ))}
      </div>
    </section>
  )
}

export default ReviewsSection
