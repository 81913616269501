import React, { useState, useEffect } from "react"

import Typography from "../../components/shared/Typography"

import CTALink from "./CTALink"

const bgImageClasses = [
  "bg-[url('~/public/images/homepage/hero_images/image-1.webp')]",
  "bg-[url('~/public/images/homepage/hero_images/image-2.webp')]",
  "bg-[url('~/public/images/homepage/hero_images/image-3.webp')]",
  "bg-[url('~/public/images/homepage/hero_images/image-4.webp')]",
  "bg-[url('~/public/images/homepage/hero_images/image-5.webp')]"
]
const randomIndex = Math.floor(Math.random() * bgImageClasses.length)
const currentBgImageClass = bgImageClasses[randomIndex]

const HeroSection = () => {
  const [ctaButtonIsSticky, setCtaButtonIsSticky] = useState(false)
  const [ctaButtonLeftOffset, setCtaButtonLeftOffset] = useState(0)

  useEffect(() => {
    const ctaButton = document.getElementById("cta-button")
    const stickyPoint = ctaButton.offsetTop
    setCtaButtonLeftOffset(ctaButton.offsetLeft)

    const handleScroll = () => setCtaButtonIsSticky(window.scrollY > stickyPoint)

    window.addEventListener("scroll", handleScroll)
    handleScroll()

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <section className="h-[75vh]">
      <div
        className={`mb-10 h-[75vh] bg-cover bg-center bg-no-repeat bg-blend-multiply sm:mb-5 ${currentBgImageClass}`}>
        <div className="h-full w-full bg-black bg-opacity-[0.64]">
          <div className="mx-auto flex h-full max-w-splash-container items-center hplg:px-8 sm:items-end sm:px-6">
            <div className="flex max-w-3xl flex-col gap-4 py-24 sm:pb-16">
              <Typography variant="hxl" className="leading-tight text-white">
                Book with 4000+
                <br />
                holistic practitioners
              </Typography>
              <Typography variant="h3" as="p" className="mb-4 font-normal leading-normal text-white">
                From massage therapists to reiki masters, nutritionists to naturopaths, and everything in between.
              </Typography>
              {!ctaButtonIsSticky && (
                <CTALink id="cta-button" href="/practitioner-search">
                  Find a practitioner
                </CTALink>
              )}
            </div>
          </div>
        </div>
      </div>
      {ctaButtonIsSticky && (
        <div
          className="fixed top-[12px] z-9998 py-2 sm:-left-4 sm:top-[63px] sm:mx-4 sm:w-full sm:border-y sm:border-gray-light sm:bg-white sm:px-6 sm:py-4"
          style={{ left: ctaButtonLeftOffset }}>
          <CTALink id="cta-button" href="/practitioner-search" className="py-2">
            Find a practitioner
          </CTALink>
        </div>
      )}
    </section>
  )
}

export default HeroSection
