import React from "react"
import { twMerge } from "tailwind-merge"

import { ButtonLink } from "../../components/shared/Links"

const CTALink = ({ className, href = "/practitioner-search", children = "Find a practitioner", ...rest }) => (
  <ButtonLink
    {...rest}
    className={twMerge("block px-10 py-4 text-center hover:shadow active:bg-teal-dark sm:w-full", className)}
    href={href}>
    {children}
  </ButtonLink>
)

export default CTALink
