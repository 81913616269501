import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import React, { useState } from "react"

import { Button } from "../../components/shared/Buttons"
import { ButtonLink } from "../../components/shared/Links"
import Typography from "../../components/shared/Typography"

const TherapyCategoriesSection = ({ therapyCategories }) => {
  const [showAll, setShowAll] = useState(false)

  const limitedTherapyCategories = therapyCategories.slice(0, 13)
  const fullTherapyCategories = therapyCategories

  return (
    <section className="mx-auto max-w-splash-container py-10 hplg:px-8 sm:px-6 sm:py-5">
      <Typography variant="h3" as="h2" className="mb-4">
        Explore holistic services
      </Typography>
      <div className="columns-4 lg:columns-3 md:columns-2 sm:hidden">
        {fullTherapyCategories.map((category) => (
          <a
            className="group mb-2 flex items-center text-gray-dark hover:font-bold hover:text-black hover:underline"
            href={`/practitioner-search?query=${encodeURIComponent(category)}`}
            key={category}>
            {category}
            <span className="-mx-16 h-6 w-6 translate-x-16 text-gray-dark opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100">
              <ChevronRightIcon />
            </span>
          </a>
        ))}
      </div>
      <ButtonLink
        href="/practitioner-search"
        className="mt-4 flex items-center justify-center border border-teal bg-white text-teal active:bg-gray-light sm:hidden">
        Explore all services <ArrowRightIcon className="ml-2 h-6 w-6" />
      </ButtonLink>

      <div className="hidden sm:flex sm:flex-col">
        {(showAll ? fullTherapyCategories : limitedTherapyCategories).map((category) => (
          <a
            className="mb-2 text-gray-dark hover:font-bold hover:text-black hover:underline"
            href={`/practitioner-search?query=${encodeURIComponent(category)}`}
            key={category}>
            {category}
          </a>
        ))}
        {!showAll && (
          <div className="w-full bg-gradient-to-t from-white sm:-mt-8">
            <Button type="tertiary" onClick={() => setShowAll(true)} className="mx-auto sm:block">
              Show all services
            </Button>
          </div>
        )}
        {showAll && (
          <ButtonLink
            href="/practitioner-search"
            className="mt-4 hidden w-full items-center justify-center border border-teal bg-white text-teal sm:flex">
            Explore all services <ArrowRightIcon className="ml-2 h-6 w-6" />
          </ButtonLink>
        )}
      </div>
    </section>
  )
}

export default TherapyCategoriesSection
