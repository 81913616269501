import React from "react"

import Typography from "../../components/shared/Typography"

import CTALink from "./CTALink"

const PractitionerJoinCTA = () => (
  <section className="mx-auto flex max-w-splash-container px-0 py-10 sm:py-5">
    <div className="w-[816px] bg-orange-light px-20 py-24 hplg:w-full sm:p-8">
      <Typography variant="h2" className="!leading-tight">
        <span>Holistic practitioner? Apply today.</span>
      </Typography>
      <p className="mb-6 mt-4">
        Grow your practice with the all-in-one platform and marketplace for holistic professionals.
      </p>
      <CTALink href="https://heal.me/lp/application">Apply Now</CTALink>
    </div>
    <div className="w-[384px] hplg:hidden">
      <img className="h-full w-full" src="/images/homepage/pro-cta.webp" alt="Practitioner" loading="lazy" />
    </div>
  </section>
)

export default PractitionerJoinCTA
