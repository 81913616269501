require("@rails/ujs").start()
require("../src/application/toggle-controls")

import "../stylesheets/home-search.css.sass"
import ReactOnRails from "react-on-rails"

import MobileMenu from "../components/header/MobileMenu"
import HomePage from "../pages/HomePage"
// eslint-disable-next-line no-unused-vars
import dayJsPlugins from "../utils/dayJsPlugins"

const components = {
  HomePage,
  MobileMenu
}

ReactOnRails.register(components)
